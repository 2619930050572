@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@keyframes spin-slow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin-slow 20s linear infinite;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.App {
  padding: 20px;
  background-color: #f9f9f9;
  font-family: 'Arial, sans-serif';
}

.table-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.e-grid {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.e-headercell {
  background-color: #007bff !important;
  color: #fff !important;
  font-weight: bold;
}

.e-row {
  background-color: #ffffff;
}

.e-row:hover {
  background-color: #f1f8ff !important;
}

.e-rowcell {
  border-bottom: 1px solid #ddd;
}
/* Pagination container */
.e-pagercontainer {
  background-color: #f1f1f1; /* Light background for pagination area */
  padding: 10px;
  display: flex;
  justify-content: center; /* Center align the pagination buttons */
  align-items: center;
  border-top: 1px solid #ddd; /* Border to separate pagination from the grid */
  margin-top: 10px;
  border-radius: 0 0 8px 8px; /* Match grid's rounded corners */
}

/* Pagination buttons */
.e-pager .e-numericcontainer a {
  color: #007bff; /* Blue color for buttons */
  padding: 5px 10px;
  margin: 0 5px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 4px;
  transition:
    background-color 0.2s,
    color 0.2s;
}

/* Hover effect for buttons */
.e-pager .e-numericcontainer a:hover {
  background-color: #007bff; /* Blue background */
  color: #fff; /* White text */
}

/* Active page button */
.e-pager .e-numericcontainer .e-active {
  background-color: #007bff; /* Blue background for active button */
  color: #fff !important; /* White text for active button */
  font-weight: bold;
}

/* Disable previous/next buttons */
.e-pager .e-disable {
  color: #ccc !important; /* Gray color for disabled buttons */
  pointer-events: none; /* Disable click */
  cursor: not-allowed;
}

/* Align pagination information (like "1 of 5 pages") */
.e-pager .e-pagesizes {
  margin-left: auto;
  font-size: 14px;
  color: #666;
}
/* Toolbar styling */
.e-toolbar {
  background-color: #f8f9fa;
  border: none;
  padding: 10px;
}

.e-toolbar-item button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  margin: 0 5px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}

.e-toolbar-item button:hover {
  background-color: #0056b3;
}

/* Grid header styling */
.e-gridheader {
  background-color: #343a40;
  color: #ffffff;
}

.e-headercell {
  font-weight: bold;
  text-align: center;
}

/* Grid row styling */
.e-row {
  background-color: #ffffff;
  color: #212529;
}

.e-row:nth-child(odd) {
  background-color: #f8f9fa;
}

/* Grid row hover effect */
.e-row:hover {
  background-color: #e9ecef;
}

/* Pagination styling */
.e-pager {
  background-color: #f8f9fa;
  padding: 10px 0;
  text-align: center;
}

.e-pager .e-numericitem {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  color: #212529;
  padding: 8px 12px;
  margin: 0 2px;
  border-radius: 4px;
}

.e-pager .e-numericitem:hover,
.e-pager .e-numericitem.e-active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}
